<template>
  <v-form ref="form" v-model="isValidForm" autocomplete="off" @submit.prevent="createInvestCompany">
    <v-text-field v-model="investCompany.name" required :rules="[v => !!v || 'Не введено название']" label="Название" />

    <v-checkbox v-model="forRuStocksProxy" label="Для русских акций" />

    <v-checkbox v-model="withDefautExpertProxy" label="Создать 'Команда аналитиков'" />

    <div class="mt-4">
      <v-btn type="submit" color="primary mr-4" :loading="loading">Добавить Инвест-дом</v-btn>
    </div>
  </v-form>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

const defaultInvestCompanyDto = {
  name: "",
  forRuStocks: true,
  withDefautExpert: true
};

Object.freeze(defaultInvestCompanyDto);

export default {
  data() {
    return {
      loading: false,
      investCompany: { ...defaultInvestCompanyDto },

      isValidForm: true
    };
  },
  computed: {
    createInvestCompanyDto() {
      return { ...this.investCompany };
    },
    forRuStocksProxy: {
      get() {
        return this.investCompany.forRuStocks;
      },
      set(value) {
        this.investCompany.forRuStocks = value;
      }
    },
    withDefautExpertProxy: {
      get() {
        return this.investCompany.withDefautExpert;
      },
      set(value) {
        this.investCompany.withDefautExpert = value;
      }
    }
  },
  methods: {
    async createInvestCompany() {
      if (!this.isValidForm) {
        this.$refs.form.validate();

        return;
      }

      this.loading = true;

      try {
        await this.$axios.$post("investment-companies", this.createInvestCompanyDto);
        await this.$parent.refreshTable();

        this.clear();

        this.$snackbar.success("Инвест-дом создан");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.createInvestCompanyDto)).toHtml();

        this.$snackbar.error("Не удалось создать инвест-дом: " + html);
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.investCompany = { ...defaultInvestCompanyDto };
    }
  }
};
</script>
