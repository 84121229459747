<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" sm="6" lg="4" xl="3">
        <v-text-field label="Поиск" :value="tableParams.search" clearable @input="setField('search', $event)" />
      </v-col>

      <v-col cols="12" sm="6" lg="4" xl="3">
        <v-checkbox v-model="isRuStocks" label="Для русских акций" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  },
  computed: {
    isRuStocks: {
      get() {
        return this.tableParams.forRuStocks;
      },
      set(value) {
        this.setField("forRuStocks", value ? value : null);
      }
    }
  }
};
</script>
