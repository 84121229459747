<template>
  <div>
    <h2 class="mb-4">Инвест-дома</h2>

    <invest-companies-filters :table-params="tableParams" @change-field="setTableParams" />

    <data-table ref="dataTable" url="investment-companies" :headers="headers" :table-params="tableParams">
      <template v-slot:[`item.forRuStocks`]="{ item: investCompany }">
        {{ investCompany.forRuStocks | yesNo }}
      </template>

      <template v-slot:[`item.expertsCount`]="{ item: investCompany }">
        {{ investCompany.expertsCount | number({ fractionDigits: 0 }) }}
      </template>

      <template v-slot:[`item.synonymsCount`]="{ item: investCompany }">
        {{ investCompany.synonymsCount | number({ fractionDigits: 0 }) }}
      </template>

      <template v-slot:[`item.actions`]="{ item: investCompany }">
        <div class="d-flex">
          <v-btn icon color="accent" @click="editInvestCompany(investCompany)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
    </data-table>

    <v-divider class="my-6" />

    <div class="text-h6 mb-3">Новый инвест-дом</div>

    <create-invest-companies-form />
  </div>
</template>

<script>
import { editorForm } from "~/mixins/multi-editor/editor-form";

import InvestCompaniesFilters from "~/components/invest-companies/filters";
import CreateInvestCompaniesForm from "~/components/invest-companies/create-invest-companies-form";

export default {
  mixins: [editorForm],
  data() {
    return {
      headers: [
        { text: "Инвест-дом", value: "name", sortable: true },
        { text: "Кол-во экспертов", value: "expertsCount", sortable: true },
        { text: "Для русских акций", value: "forRuStocks" },
        { text: "Кол-во синонимов", value: "synonymsCount", sortable: true },
        { text: "Действия", value: "actions", sortable: false }
      ],
      tableParams: {
        forRuStocks: true
      }
    };
  },
  methods: {
    refreshTable() {
      return this.$refs.dataTable.update();
    },
    editInvestCompany(investCompany) {
      const route = this.$router.resolve({ name: "invest-companies-id", params: { id: investCompany.id } });

      window.open(route.href, "_blank");
    },
    setTableParams(payload) {
      if (payload.value === null) {
        this.tableParams = {};
      } else {
        this.commitChange(this.tableParams, payload);
      }
    }
  },
  components: {
    InvestCompaniesFilters,
    CreateInvestCompaniesForm
  }
};
</script>
